/* RegisterWidget */
#widget-container {
    margin: 1rem;
    border-radius: 1rem;
}
#widget-container .widget-card-content .content-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
}
#widget-container .widget-card-content .content-value .textField {
    width: 100%;
    display: flex;
}
#widget-container .widget-card-content .content-value .switchField {
    width: 10%;
    display: flex;
    background: cornflowerblue;
}
#widget-container .footer-card-content {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
/* #widget-container .footer-card-content .free {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
#widget-container .footer-card-content .free .title {
    margin-right: 0.5rem;
} */

/* ConfirmBarcodeDialog */
.dialog-title {
    text-align: center;
}
.content-confirm-barcode {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content-confirm-barcode .icon {
    display: flex;
    justify-content: center;
}
.content-confirm-barcode .section-name {
    color: #7b7b7b;
}
.content-confirm-barcode .first{
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #afafaf;
}
.content-confirm-barcode .content-data{
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #afafaf;
}
.content-confirm-barcode .content {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}
.content-confirm-barcode .content .title {
    margin-right: 10px;
}
.content-free{
    display: flex;
    justify-content: flex-end;
}
.content-free .free{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.content-free .free .title{
    margin-right: 0.5rem;
}


@media (max-width: 500px) {
    
    /* RegisterWidget */
    #widget-container .widget-card-content .content-value {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    #widget-container .widget-card-content .content-value .textField {
        width: 100%;
    }
}