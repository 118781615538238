#drawer-my-data .avatar {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 159, 51);
}


/* Loader */
#loader img {
    margin-bottom: 2rem;
    animation: loading 2s infinite alternate;
}

@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}