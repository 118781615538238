/* FormLogin */
#login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#login-container .form {
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(1);
}
#login-container .form .form-itens {
    margin: 1rem;
    min-width: 200px;
}
#login-container .button-recover-password {
    border: none;
    background: none;
    font-size: 1rem;
    color: #008698;
}
#login-container .button-recover-password:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #008698;
    color: #008698;
    background: none;
    font-size: 1rem;
}


/* RecoverPassword */
#recover-password-username-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}
#recover-password-username-container p {
    text-align: center;
    margin: 1.5rem 0;
    color: rgb(60, 60, 60);
}
#recover-password-username-container .form-itens {
    margin: 1rem;
    min-width: 400px;
}
#recover-password-username-container .form-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}