#dashboard-history-content-without-data {
    height: 50vh;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem;
}

#dashboard-history-content-without-data .no-result-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#dashboard-history-content-with-data {
    height: 100%;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem;
}

#dashboard-history-content-with-data .result-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
}